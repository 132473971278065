import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
import { graphql } from 'gatsby';
import ComponentApi from '../../components/ComponentApi';
import ReactPlayground from '../../components/ReactPlayground';
import BadgeBasic from '../../examples/Badge/Basic';
import BadgeButton from '../../examples/Badge/Button';
import BadgePill from '../../examples/Badge/Pill';
import BadgeVariations from '../../examples/Badge/Variations';
export const query = graphql`
  query BadgeQuery {
    metadata: componentMetadata(displayName: { eq: "Badge" }) {
      displayName
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "badges"
    }}>{`Badges`}</h1>
    <p>{`Badges scale to match the size of the immediate parent element by
using relative font sizing and em units.`}</p>
    <ReactPlayground codeText={BadgeBasic} mdxType="ReactPlayground" />
    <p>{`Badges can be used as part of links or buttons to provide a counter.`}</p>
    <ReactPlayground codeText={BadgeButton} mdxType="ReactPlayground" />
    <p>{`Note that depending on how they are used, badges may be confusing for
users of screen readers and similar assistive technologies. While the
styling of badges provides a visual cue as to their purpose, these users
will simply be presented with the content of the badge. Depending on the
specific situation, these badges may seem like random additional words or
numbers at the end of a sentence, link, or button. Unless the context is
clear, consider including additional context with a visually hidden piece
of additional text.`}</p>
    <h2 {...{
      "id": "contextual-variations"
    }}>{`Contextual variations`}</h2>
    <p>{`Add any of the below mentioned modifier classes to change the
appearance of a badge.`}</p>
    <ReactPlayground codeText={BadgeVariations} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "pill"
    }}>{`Pill`}</h2>
    <p>{`badges Use the `}<inlineCode parentName="p">{`pill`}</inlineCode>{` modifier class to make badges more rounded
(with a larger `}<inlineCode parentName="p">{`border-radius`}</inlineCode>{` and additional horizontal `}<inlineCode parentName="p">{`padding`}</inlineCode>{`).
Useful if you miss the badges from v3.`}</p>
    <ReactPlayground codeText={BadgePill} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <ComponentApi metadata={props.data.metadata} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      